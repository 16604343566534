import React from "react";
import "./AchievementsBgSlider.css";

const AchievementsBgSlider = () => {
    return (
        <>
        <div className="tslider-top">
            <div className="text-slider">
                <div className="slider-text">
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                </div>
                <div className="slider-text">
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                </div>   
            </div>
        </div>

        <div className="tslider-bottom">
            <div className="text-slider">
                <div className="slider-text">
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                </div>
                <div className="slider-text">
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                    <span>Nakamura's History</span>
                </div>   
            </div>
        </div>
        </>
    )
}

export default AchievementsBgSlider;