import React from "react";
import "./MachineBlocks.css";
import AOS from "aos";
import "aos/dist/aos.css";
import M1 from "../img/machine01.jpg";
import M2 from "../img/machine02.jpg";
import M3 from "../img/machine03.jpg";
import M4 from "../img/machine04.jpg";
import M5 from "../img/machine05.jpg";
import M6 from "../img/machine06.jpg";
import M7 from "../img/machine07.jpg";
import M8 from "../img/machine08.jpg";
import M9 from "../img/machine09.jpg";
import M10 from "../img/machine10.jpg";
import M11 from "../img/machine11.jpg";
import M12 from "../img/machine12.jpg";

const MachineBlocks = () => {

    React.useEffect(() => { 
        AOS.init({
            duration: 2000,
        });
    },[]);

    return(
        <>
        <div className="bg-logo" />
        <div className="machine-top">
            <div className="machine-pr">
                <h2 className="machine-title">
                    設備紹介
                </h2>
                <p className="machine-message">
                    新たに油圧プレス機、パイプベンダーが加わり、<br/>
                    より迅速に、正確に、そして多くのニーズに応えられるようになりました。
                </p>
            </div>
        </div>
        <div className="machine-blocks">
            <div className="machine-item">
                <div className="machine-block left img">
                    <img className="machine-img" data-aos="fade-up" src={ M1 } />
                </div>
                <div className="machine-block right">
                    <h3 className="machine-name">
                        ベンディングロール
                    </h3>
                    <img className="machine-img-resp" src={ M1 } />
                    <p className="machine-description">
                        型式 TPB-M-6×2050 (神埼工業)
                    </p>
                    <table class="machine-2">
                        <thead>
                        <tr>
                            <th scope="cols">板厚</th>
                            <th scope="cols">ロール径 (mm)</th>
                            <th scope="cols">上ロール径 (mm)</th>
                            <th scope="cols">下ロール径 (mm)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>6</td>
                            <td>2050</td>
                            <td>220</td>
                            <td>140</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left">
                    <h3 className="machine-name">
                        油圧プレス機
                    </h3>
                    <img className="machine-img-resp" src={ M2 } />
                    <p className="machine-description">
                        型式 KSOP-150 (KIMURA)
                    </p>
                    <table class="machine-1">
                        <tr>
                            <th scope="row">能力</th>
                            <td>150ton</td>
                        </tr>
                        <tr>
                            <th scope="row">曲げ長さ</th>
                            <td>2000mm</td>
                        </tr>
                    </table>

                    <table class="machine-2">
                        <thead>
                        <tr>
                            <th scope="cols">厚さ</th>
                            <th scope="cols">幅</th>
                            <th scope="cols">R</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>16t</td>
                            <td>2000</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>22t</td>
                            <td>1500</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>32t</td>
                            <td>500</td>
                            <td>1000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="machine-block right img">
                    <img className="machine-img" data-aos="fade-up" src={ M2 } />
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left img">
                    <img className="machine-img" data-aos="fade-up" src={ M3 } />
                </div>
                <div className="machine-block right">
                    <h3 className="machine-name">
                        パイプベンダー
                    </h3>
                    <img className="machine-img-resp" src={ M3 } />
                    <p className="machine-description">
                        型式 TB=DR-2HANC (太洋)
                    </p>
                    <table class="machine-2">
                        <thead>
                        <tr>
                            <th scope="cols">サイズ</th>
                            <th scope="cols">外径</th>
                            <th scope="cols">R</th>
                            <th scope="cols">R</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>20A</td>
                            <td>27.2</td>
                            <td>80</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>32φ</td>
                            <td>32</td>
                            <td>100</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>25A</td>
                            <td>34</td>
                            <td>100</td>
                            <td>150</td>
                        </tr>
                        <tr>
                            <td>38φ</td>
                            <td>38</td>
                            <td>100</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>32A</td>
                            <td>42.7</td>
                            <td>100</td>
                            <td>150</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left">
                    <h3 className="machine-name">
                        ベンディングロール
                    </h3>
                    <img className="machine-img-resp" src={ M4 } />
                    <p className="machine-description">
                        型式 TAH－30At (金型上下方式・NC制御)
                    </p>
                    <table class="machine-2">
                        <thead>
                            <tr>
                                <th scope="cols">板厚</th>
                                <th scope="cols">ロール径(mm)</th>
                                <th scope="cols">上ロール径(mm)</th>
                                <th scope="cols">下ロール径(mm)</th>
                                <th scope="cols">電動機(kw)</th>
                                <th scope="cols">油圧ポンプ(kw)</th>
                            </tr>
                        </thead>
                            <tbody>
                            <tr>
                                <td>16</td>
                                <td>3200</td>
                                <td>480</td>
                                <td>260</td>
                            <td>15</td>
                            <td>2.2</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="machine-block right img">
                    <img className="machine-img" data-aos="fade-up" src={ M4 } />
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left img">
                    <img className="machine-img" data-aos="fade-up" src={ M5 } />
                </div>
                <div className="machine-block right">
                    <h3 className="machine-name">
                        プレスブレーキ
                    </h3>
                    <img className="machine-img-resp" src={ M5 } />
                    <p className="machine-description">
                        PHS 300
                    </p>
                    <table class="machine-1">
                        <tr>
                            <th scope="row">能力</th>
                            <td>300ton</td>
                        </tr>
                        <tr>
                            <th scope="row">曲げ長さ</th>
                            <td>5100mm</td>
                        </tr>
                        <tr>
                            <th scope="row">奥行き</th>
                            <td>1500mm</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left">
                    <h3 className="machine-name">
                        プレスブレーキ
                    </h3>
                    <img className="machine-img-resp" src={ M6 } />
                    <p className="machine-description">
                        PHS 125
                    </p>
                    <table class="machine-1">
                        <tr>
                            <th scope="row">能力</th>
                            <td>125ton</td>
                        </tr>
                        <tr>
                            <th scope="row">曲げ長さ</th>
                            <td>3100mm</td>
                        </tr>
                        <tr>
                            <th scope="row">奥行き</th>
                            <td>1435mm</td>
                        </tr>
                    </table>
                </div>
                <div className="machine-block right img">
                    <img className="machine-img" data-aos="fade-up" src={ M6 } />
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left img">
                    <img className="machine-img" data-aos="fade-up" src={ M7 } />
                </div>
                <div className="machine-block right">
                    <h3 className="machine-name">
                        プレスブレーキ
                    </h3>
                    <img className="machine-img-resp" src={ M7 } />
                    <p className="machine-description">
                        PHS 50×125
                    </p>
                    <table class="machine-1">
                        <tr>
                            <th scope="row">能力</th>
                            <td>50ton</td>
                        </tr>
                        <tr>
                            <th scope="row">曲げ長さ</th>
                            <td>1250mm</td>
                        </tr>
                        <tr>
                            <th scope="row">奥行き</th>
                            <td>1060mm</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left">
                    <h3 className="machine-name">
                        製缶プレス
                    </h3>
                    <img className="machine-img-resp" src={ M8 } />
                    <p className="machine-description">
                        プレス (理研)
                    </p>
                    <table class="machine-1">
                        <tr>
                            <th scope="row">能力</th>
                            <td>600ton</td>
                        </tr>
                        <tr>
                            <th scope="row">曲げ長さ</th>
                            <td>2800mm</td>
                        </tr>
                    <tr>
                            <th scope="row">奥行</th>
                            <td>3500mm</td>
                        </tr>
                    </table>
                </div>
                <div className="machine-block right img">
                    <img className="machine-img" data-aos="fade-up" src={ M8 } />
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left img">
                    <img className="machine-img" data-aos="fade-up" src={ M9 } />
                </div>
                <div className="machine-block right">
                    <h3 className="machine-name">
                        パイプ
                    </h3>
                    <img className="machine-img-resp" src={ M9 } />
                    <p className="machine-description">
                        油圧ベンダー (太洋)
                    </p>
                    <table class="machine-2">
                        <thead>
                        <tr>
                            <th scope="cols">A</th>
                            <th scope="cols">外径</th>
                            <th scope="cols">R</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>15A</td>
                                <td>21.7</td>
                                <td>50</td>
                            </tr>
                            <tr>
                                <td>20A</td>
                                <td>27.2</td>
                                <td>50<br/>100</td>
                            </tr>
                            <tr>
                                <td>25A</td>
                                <td>34.0</td>
                                <td>80<br/>100</td>
                            </tr>
                            <tr>
                                <td>32A</td>
                                <td>42.7</td>
                                <td>100</td>
                            </tr>
                            <tr>
                                <td>40A</td>
                                <td>48.6</td>
                                <td>120</td>
                            </tr>
                            <tr>
                                <td>50A</td>
                                <td>60.5</td>
                                <td>120<br/>150<br/>250</td>
                            </tr>
                            <tr>
                                <td>65A</td>
                                <td>76.3</td>
                                <td>200</td>
                            </tr>
                            <tr>
                                <td>80A</td>
                                <td>89.1</td>
                                <td>300</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left">
                    <h3 className="machine-name">
                        シャーリング
                    </h3>
                    <img className="machine-img-resp" src={ M10 } />
                    <p className="machine-description">
                        型式 SHS10
                    </p>
                    <table class="machine-1">
                        <tr>
                            <th scope="row">切断板厚</th>
                            <td>10</td>
                        </tr>
                        <tr>
                            <th scope="row">ステンレス</th>
                            <td>6</td>
                        </tr>
                        <tr>
                            <th scope="row">切断長さ</th>
                            <td>3100</td>
                        </tr>
                    </table>

                    <p className="machine-description">
                        型式 SHS4
                    </p>
                    <table class="machine-1">
                        <tr>
                            <th scope="row">切断板厚</th>
                            <td>4.5</td>
                        </tr>
                        <tr>
                            <th scope="row">ステンレス</th>
                            <td>3</td>
                        </tr>
                    <tr>
                            <th scope="row">切断長さ</th>
                            <td>3100</td>
                        </tr>
                    </table>
                </div>
                <div className="machine-block right img">
                    <img className="machine-img" data-aos="fade-up" src={ M10 } />
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left img">
                    <img className="machine-img" data-aos="fade-up" src={ M11 } />
                </div>
                <div className="machine-block right">
                    <h3 className="machine-name">
                        シャーリング
                    </h3>
                    <img className="machine-img-resp" src={ M11 } />
                    <table class="machine-1">
                        <tr>
                            <th scope="row">切断板厚</th>
                            <td>12</td>
                        </tr>
                        <tr>
                            <th scope="row">ステンレス</th>
                            <td>9</td>
                        </tr>
                        <tr>
                            <th scope="row">切断長さ</th>
                            <td>1200</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="machine-item">
                <div className="machine-block left">
                    <h3 className="machine-name">
                        その他
                    </h3>
                    <img className="machine-img-resp" src={ M12 } />
                    <div className="machine-list">
                        <p>
                            エアープラズマ
                        </p>
                        <p>
                            ラジアンボール盤
                        </p>
                        <p>
                            溶接機300A 3基
                        </p>
                        <p>
                            コーナーシャ―
                        </p>
                        <p>
                            ベンディングロール<br/>
                            6m/m 1,800ℓ 1基<br/>
                            10m/m 3,000ℓ 1基
                        </p>
                        <p>
                            天井走行クレーン<br/>
                            2.8t 8台
                        </p>
                    </div>
                </div>
                <div className="machine-block right img">
                    <img className="machine-img" data-aos="fade-up" src={ M12 } />
                </div>
            </div>
        </div>
        </>   
    )
}

export default MachineBlocks;