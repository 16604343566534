import React from "react";
import History from "./achievements/history/History";
import { Header, Footer, ContactPopup } from "./index";

const Achievements = () => {
    
    return(
        <div className="achievements" id="page">
            <Header />
            <History />
            <Footer />
            <ContactPopup />
        </div> 
    )
}

export default Achievements;