import React from "react";
import { Helmet } from "react-helmet";
import Favicon from "./templates/img/favicon.ico";
import TwitterCard from "./templates/img/tc.jpg";

const Head = () => {
    return (
        <Helmet>
            <meta charset="utf-8" />
            <title>（有）中村設備工業</title>  
            <link rel="icon" href={Favicon} />
            <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0"></meta>
            <meta
            name="description"
            content="曲げは中村へ。曲げ加工という専門技術を確実に次世代へ伝えつつ、お客さまのニーズに確実にお応えできる設備と技術を備え、各種鋼材加工のあらゆる仕様に対応しています。"
            />
            <meta name="keywords"
            content="中村設備,中村設備工業,曲げ,切断,鋼材,加工,大分,九州"
            />
            {/* OGP ここから */}
            <head prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# website: http://ogp.me/ns/website#" />
            <meta property="og:url" content="https://nakamurasetubi.co.jp" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="（有）中村設備工業" />
            <meta property="og:description" content="曲げは中村へ。曲げ加工という専門技術を確実に次世代へ伝えつつ、お客さまのニーズに確実にお応えできる設備と技術を備え、各種鋼材加工のあらゆる仕様に対応しています。" />
            <meta property="og:site_name" content="（有）中村設備工業" />
            <meta property="og:image" content={TwitterCard} />
            {/* OGP ここまで */}
            {/* Twitter Card ここから */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@nakamurasetubi__" />
            <meta name="twitter:title" content="（有）中村設備工業" />
            <meta name="twitter:description" content="曲げは中村へ。曲げ加工という専門技術を確実に次世代へ伝えつつ、お客さまのニーズに確実にお応えできる設備と技術を備え、各種鋼材加工のあらゆる仕様に対応しています。" />
            <meta name="twitter:image" content={TwitterCard} />
            {/* Twitter Card ここまで */}
        </Helmet>
    )
}

export default Head