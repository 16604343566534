import React from "react";
import { Header, Footer, ContactPopup } from "./index";
import SpecTabs from "./specs/specTabs/SpecTabs";

const Specs = () => {
    
    return(
        <div className="specs" id="page">
            <Header />
            <SpecTabs />
            <Footer />
            <ContactPopup />
        </div> 
    )
}

export default Specs;