import React from "react";
import "./Footer.css";
import { UpArrow, Facebook, Youtube } from "../../index";
import { Link as Scroll } from "react-scroll";
import sdgs3 from "../../img/sdgs/sdgs3.png";
import sdgs4 from "../../img/sdgs/sdgs4.png";
import sdgs5 from "../../img/sdgs/sdgs5.png";
import sdgs8 from "../../img/sdgs/sdgs8.png";
import sdgs9 from "../../img/sdgs/sdgs9.png";
import sdgs11 from "../../img/sdgs/sdgs11.png";
import sdgs12 from "../../img/sdgs/sdgs12.png";
import sdgs15 from "../../img/sdgs/sdgs15.png";


const Footer = () => {

    return(
        <div className="footer">
            <div className="footer-area">
                <Scroll to="page" className="up-arrow" duration={0}>
                    <UpArrow className={"uparrow-icon"} width={35} height={35} fill={"#000"} />
                </Scroll>
                <div className="clear" />
                <div className="footer-menu">
                    <div className="footer-menu-block">
                        <div className="footer-menu-list">
                            <a href="/achievements" className="footer-menu-item">
                                主な実績
                            </a>
                        </div>
                        <div className="footer-menu-list">
                            <a href="/products" className="footer-menu-item">
                                商品紹介
                            </a>
                        </div>
                        <div className="footer-menu-list">
                            <a href="/machines" className="footer-menu-item">
                                設備紹介
                            </a>
                        </div>
                    </div>
                    <div className="footer-menu-block">
                        <div className="footer-menu-list">
                            <a href="/specs" className="footer-menu-item">
                                加工仕様 / 最小曲半径表
                            </a>
                        </div>
                        <div className="footer-menu-list">
                            <a href="/interiors" className="footer-menu-item">
                                曲げインテリア
                            </a>
                        </div>
                    </div>
                </div>
                <div className="clear" />
                    <div className="footer-sdgs">
                        <h4 className="footer-sdgs-title">
                            わたしたちはSDGs達成に貢献しています
                        </h4>
                        <div className="footer-sdgs-icon">
                            <img src={ sdgs3 } />
                        </div>
                        <div className="footer-sdgs-icon">
                            <img src={ sdgs4 } />
                        </div>
                        <div className="footer-sdgs-icon">
                            <img src={ sdgs5 } />
                        </div>
                        <div className="footer-sdgs-icon">
                            <img src={ sdgs8 } />
                        </div>
                        <div className="footer-sdgs-icon">
                            <img src={ sdgs9 } />
                        </div>
                        <div className="footer-sdgs-icon">
                            <img src={ sdgs11 } />
                        </div>
                        <div className="footer-sdgs-icon">
                            <img src={ sdgs12 } />
                        </div>
                        <div className="footer-sdgs-icon">
                            <img src={ sdgs15 } />
                        </div>
                    </div>
                <div className="clear" />
                <div className="footer-socials">
                    <a href="https://www.facebook.com/1297137947061963/" target="_blank" className="footer-social">
                        <Facebook className={"footer-fb-icon"} width={25} height={25} fill={"#000"} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCdHYFVkVxzC__TwKPxQtYsg" target="_blank" className="footer-social">
                        <Youtube className={"footer-yt-icon"} width={27} height={25} fill={"#000"} />
                    </a>
                </div>
                <div className="clear" />
                <p className="copyright" > &copy;{new Date().getFullYear()} &#12850;中村設備工業</p>
            </div>
        </div>     
    )
}
export default Footer;