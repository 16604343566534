import React from "react";
import "./ContactPopup.css"
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Contact from "../icons/Contact";

const ContactPopup = () => {

    return(
        <Popup
            trigger={<a className="contact-popup"><Contact className={"contact-icon"} width={45} height={45} fill={"#fff"} /></a>}
            modal
            nested
        >
            {close => (
            <div className="modal">
                <div className="contact-popup-content">
                    <h3 className="contact-popup-title">
                        お問い合わせ
                    </h3>
                    <div className="contact-popup-number">
                        電話：<a href="tel:0975513218">097-551-3218</a>
                    </div>
                    <div className="contact-popup-number">
                        FAX：097-551-3269
                    </div>
                    <div className="contact-popup-message">
                        (営業時間：8:00-17:00、土日祝除く)
                    </div>
                    <div className="contact-popup-email">
                        メール：
                        <a href="mailto:info@nakamurasetubi.co.jp">
                            info@nakamurasetubi.co.jp
                        </a>
                    </div>
                    <a className="contact-popup-close" onClick={close}>
                        CLOSE
                    </a>
                </div>
            </div>
            )}
        </Popup>
    )
}
export default ContactPopup;