import React from "react";
import InteriorPopup from "../interiorPopup/InteriorPopup";
import "./InteriorBlocks.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { SwiperSlide } from 'swiper/react';
import InteriorSlider from "../interiorSlider/InteriorSlider";
import I1 from "../img/in1.jpg";
import I11 from "../img/in1-1.jpg";
import I12 from "../img/in1-2.jpg";
import I13 from "../img/in1-3.jpg";
import I2 from "../img/in2.jpg";
import I21 from "../img/in2-1.jpg";
import I22 from "../img/in2-2.jpg";
import I3 from "../img/in3.jpg";
import I31 from "../img/in3-1.jpg";
import I32 from "../img/in3-2.jpg";
import I33 from "../img/in3-3.jpg";
import I34 from "../img/in3-4.jpg";
import I35 from "../img/in3-5.jpg";
import I36 from "../img/in3-6.jpg";
import I37 from "../img/in3-7.jpg";
import I38 from "../img/in3-8.jpg";
import I39 from "../img/in3-9.jpg";
import I310 from "../img/in3-10.jpg";
import I4 from "../img/in4.jpg";
import I41 from "../img/in4-1.jpg";
import I5 from "../img/in5.jpg";
import I51 from "../img/in5-1.jpg";
import I6 from "../img/in6.jpg";
import I61 from "../img/in6-1.jpg";
import I62 from "../img/in6-2.jpg";
import I63 from "../img/in6-3.jpg";
import I64 from "../img/in6-4.jpg";
import I7 from "../img/in7.jpg";
import I71 from "../img/in7-1.jpg";
import I8 from "../img/in8.jpg";
import I81 from "../img/in8-1.jpg";
import I82 from "../img/in8-2.jpg";
import I83 from "../img/in8-3.jpg";
import I9 from "../img/in9.jpg";
import II0 from "../img/in10.jpg";
import II01 from "../img/in10-1.jpg";
import II1 from "../img/in11.jpg";
import II11 from "../img/in11-1.jpg";
import II12 from "../img/in11-2.jpg";
import II2 from "../img/in12.jpg";
import II21 from "../img/in12-1.jpg";
import II3 from "../img/in13.jpg";
import II31 from "../img/in13-1.jpg";
import II32 from "../img/in13-2.jpg";
import II4 from "../img/in14.jpg";
import II41 from "../img/in14-1.jpg";
import II5 from "../img/in15.jpg";
import II51 from "../img/in15-1.jpg";
import II6 from "../img/in16.jpg";
import II61 from "../img/in16-1.jpg";

const InteriorBlocks = () => {
    React.useEffect(() => { 
        AOS.init({
            duration: 2000,
        });
    },[]);
    const breakpointColumnsObj = {
        default: 4,
        1050: 3,
        600: 2,
        400: 1,
    }

    return(
        <>
        <div className="bg-logo" />
        <div className="interior-top">
            <div className="interior-pr">
                <h2 className="interior-title">
                    曲げインテリア
                </h2>
                <p className="interior-message">
                    曲げ技術を生かした工芸品やアート作品を、ひとつひとつ熟練の曲げ職人が手作業で製作しております。<br/>
                    ご依頼をいただき次第の制作になりますので、カスタマイズ対応も可能です。まずは、お気軽にお問い合わせください。
                </p>
            </div>
        </div>

        <div className="interior-blocks columns-2 md:columns-3 lg:columns-4" data-aos="fade-up">
            {/* slider items */}

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={II6} />
                    </div>
                    <InteriorPopup
                        className="interior16"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II6 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II61 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        茅の輪
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={II5} />
                    </div>
                    <InteriorPopup
                        className="interior14"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II5 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II51 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        傘立て
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={II4} />
                    </div>
                    <InteriorPopup
                        className="interior14"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II4 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II41 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        パネルスタンド
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={II3} />
                    </div>
                    <InteriorPopup
                        className="interior13"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II3 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II31 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II32 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        スタンド
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={II2} />
                    </div>
                    <InteriorPopup
                        className="interior12"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II2 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II21 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        テーブル
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={II1} />
                    </div>
                    <InteriorPopup
                        className="interior11"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II1 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II11 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II12 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        天井アート
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={II0} />
                    </div>
                    <InteriorPopup
                        className="interior10"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II0 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ II01 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        壁飾り
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I9} />
                    </div>
                    <InteriorPopup
                        className="interior9"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I9 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        スロープ
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I8} />
                    </div>
                    <InteriorPopup
                        className="interior8"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I8 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I61 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I82 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I83 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        ベンチ
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I7} />
                    </div>
                    <InteriorPopup
                        className="interior7"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I7 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I71 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        コーナーラック
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I6} />
                    </div>
                    <InteriorPopup
                        className="interior6"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I6 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I61 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I63 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I64 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        サイクルスタンド
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I5} />
                    </div>
                    <InteriorPopup
                        className="interior5"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I5 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I51 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        ティッシュケース
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I4} />
                    </div>
                    <InteriorPopup
                        className="interior4"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I4 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I41 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        花器
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I3} />
                    </div>
                    <InteriorPopup
                        className="interior3"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I3 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I31 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I32 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I33 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I34 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I35 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I36 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I37 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I38 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I39 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I310 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        花器・花瓶
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I2} />
                    </div>
                    <InteriorPopup
                        className="interior2"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I2 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I21 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I22 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        ラック
                    </h3>
                </div>

                <div className="interior-item mb-4">
                    <div className="interior-item-img">
                        <img className="interior-popup-img" src={I1} />
                    </div>
                    <InteriorPopup
                        className="interior1"
                    >
                        <InteriorSlider>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I1 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I11 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I12 } />
                            </SwiperSlide>
                            <SwiperSlide className="swiper__slide">
                                <img src={ I13 } />
                            </SwiperSlide>
                        </InteriorSlider>
                    </InteriorPopup>
                    <h3 className="interior-item-name">
                        ラック
                    </h3>
                </div>
            {/* end slider items */}
        </div>
        </>
    )
}
export default InteriorBlocks;