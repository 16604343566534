import React, { useState } from "react";
import "./InteriorSlider.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from "swiper";
import "swiper/css/navigation";

const InteriorSlider = (props) => {
    const [hidden, setHidden] = useState(true);
    const showClick = () => setHidden(false);
    const hiddenClick = () => setHidden(true);
    return(
        <div className="interior-slider">
            <Swiper loop={true} navigation={ hidden ? true : false } modules={[Navigation]} className="swiper">
                { props.children }
            </Swiper>
        </div>     
    )
}

export default InteriorSlider;