import React from "react";
import { Header, Footer, ContactPopup } from "./index";
import ProductBlocks from "./products/productBlocks/ProductBlocks";

const Products = () => {

    return(
        <div className="machines" id="page">
            <Header />
            <ProductBlocks />
            <Footer />
            <ContactPopup />
        </div>
    )
}
export default Products;