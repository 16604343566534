import React from "react";
import "./Header.css";
import Navigation from "./navigation/Navigation";
import HeaderLogo from "../../img/nakamura-logo.png"

const Header = () => {

    return (
        <div id="header" className="header">
            <div className="header-content">
                <a href="/" className="header-logo">
                    <img src={HeaderLogo} alt="中村設備工業" />
                </a>
                <div className="header-nav-content">
                    <Navigation />
                </div>
            </div>
        </div>
    )
}

export default Header