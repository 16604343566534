import React from "react";
import { Header, Footer, ContactPopup } from "./index";
import InteriorBlocks from "./interiors/interiorBlocks.js/InteriorBLocks";

const Interiors = () => {

    return(
        <div className="interiors" id="page">
            <Header />
            <InteriorBlocks />
            <Footer />
            <ContactPopup />
        </div>
    )
}
export default Interiors;