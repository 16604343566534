import React from "react";
import "./ProductBlocks.css";
import AOS from "aos";
import "aos/dist/aos.css";
import P1 from "../img/product01.jpg";
import P2 from "../img/product02.jpg";
import P3 from "../img/product03.jpg";
import P4 from "../img/product04.jpg";
import P5 from "../img/product05.jpg";
import P6 from "../img/product06.jpg";
import P7 from "../img/product07.jpg";
import P8 from "../img/product08.jpg";
import P9 from "../img/product09.jpg";
import P10 from "../img/product10.jpg";
import P11 from "../img/product11.jpg";
import P12 from "../img/product12.jpg";
import P13 from "../img/product13.jpg";
import P14 from "../img/product14.jpg";
import P15 from "../img/product15.jpg";
import P16 from "../img/product16.jpg";
import P17 from "../img/product17.jpg";
import P18 from "../img/product18.jpg";
import P19 from "../img/product19.jpg";
import P20 from "../img/product20.jpg";

const ProductBlocks = () => {

    React.useEffect(() => { 
        AOS.init({
            duration: 2000,
        });
    },[]);

    return(
        <>
        <div className="bg-logo" />
        <div className="product-top">
            <div className="product-pr">
                <h2 className="product-title">
                    製品紹介
                </h2>
                <p className="product-message">
                    製品詳細に関してはいつでもお問い合せください。
                </p>
            </div>
        </div>
        <div className="product-blocks">
            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P1 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        リング曲げ
                    </h3>
                    <img className="product-img-resp" src={ P1 } />
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                    <p className="product-description">
                        種類：RB (丸鋼)
                    </p>
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        角度曲げ
                    </h3>
                    <img className="product-img-resp" src={ P2 } />
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                    <p className="product-description">
                        種類：パイプ
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P2 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P3 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        レジューサー曲げ
                    </h3>
                    <img className="product-img-resp" src={ P3 } />
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        バンド曲げ
                    </h3>
                    <img className="product-img-resp" src={ P4 } />
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P4 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P5 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        パイプベンダー
                    </h3>
                    <img className="product-img-resp" src={ P5 } />
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                    <p className="product-description">
                        種類：パイプ
                    </p>
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        ロール (端曲げ)
                    </h3>
                    <img className="product-img-resp" src={ P6 } />
                    <p className="product-description">
                        材質：ステンレス
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P6 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P7 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        プレス曲げ (90度曲げ)
                    </h3>
                    <img className="product-img-resp" src={ P7 } />
                    <p className="product-description">
                        材質：アルミ
                    </p>
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        レジューサー
                    </h3>
                    <img className="product-img-resp" src={ P8 } />
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P8 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P9 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        リング曲げ
                    </h3>
                    <img className="product-img-resp" src={ P9 } />
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        四方曲げ (箱曲げ)
                    </h3>
                    <img className="product-img-resp" src={ P10 } />
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P10 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P11 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        ケーシング
                    </h3>
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                    <img className="product-img-resp" src={ P11 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        ロール曲げ
                    </h3>
                    <img className="product-img-resp" src={ P12 } />
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P12 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P13 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        バンド
                    </h3>
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                    <img className="product-img-resp" src={ P13 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        ロール曲げ
                    </h3>
                    <img className="product-img-resp" src={ P14 } />
                    <p className="product-description">
                        材質：SUS (ステンレス)
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P14 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P15 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        角パイプ曲げ
                    </h3>
                    <img className="product-img-resp" src={ P15 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        らせん曲げ
                    </h3>
                    <img className="product-img-resp" src={ P16 } />
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                    <p className="product-description">
                        種類：パイプ
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P16 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P17 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        折り曲げ
                    </h3>
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                    <img className="product-img-resp" src={ P17 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        丸ダクト
                    </h3>
                    <img className="product-img-resp" src={ P18 } />
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P18 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left img">
                    <img className="product-img" data-aos="fade-up" src={ P19 } />
                </div>
                <div className="product-block right">
                    <h3 className="product-name">
                        角丸ダクト
                    </h3>
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                    <img className="product-img-resp" src={ P19 } />
                </div>
            </div>

            <div className="product-item">
                <div className="product-block left">
                    <h3 className="product-name">
                        エプロン曲げ
                    </h3>
                    <img className="product-img-resp" src={ P20 } />
                    <p className="product-description">
                        材質：SS (鉄)
                    </p>
                </div>
                <div className="product-block right img">
                    <img className="product-img" data-aos="fade-up" src={ P20 } />
                </div>
            </div>
        </div>
        </>   
    )
}

export default ProductBlocks;