import React from "react";
import Radius from "../radius/Radius";
import Shape from "../shape/Shape";
import "./SpecTabs.css";

const SpecTabs = () => {
    
    return(
        <div className="spec-tabs">
            <input id="shape" type="radio" name="tab_item" checked　/>
            <label className="tab_item" for="shape">曲げ加工 加工仕様</label>
            <input id="radius" type="radio" name="tab_item" />
            <label className="tab_item" for="radius">最小曲半径表</label>
            <div className="tab_content" id="shape_content">
                <div className="tab_content_description">
                    <Shape />
                </div>
            </div>
            <div className="tab_content" id="radius_content">
                <div className="tab_content_description">
                    <Radius />
                </div>
            </div>
        </div>
    )
}

export default SpecTabs;