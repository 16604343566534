import React from "react";
import "./Shape.css";
import ShapeImg from "../img/keijolist.jpg";

const Shape = () => {
    
    return(
        <div className="shape">
            <p className="shape-text">
                曲げ加工に関する仕様表です。不明点はお問い合わせください。
            </p>
            <div className="divider" />
            <img src={ ShapeImg } alt="曲げ加工 加工仕様" />
        </div> 
    )
}

export default Shape;