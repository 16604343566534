import React from "react";
import "./InteriorPopup.css"
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const InteriorPopup = (props) => {

    return(
        <Popup
            trigger={<a className={props.className}></a>}
            modal
            nested
        >
            {close => (
            <div className="modal">
                <a className="interior-popup-close" onClick={close}>
                &times;
                </a>
                <div className="interior-popup-content">
                    {props.children}
                </div>
            </div>
            )}
        </Popup>
    )
}
export default InteriorPopup;