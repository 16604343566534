import React from "react";
import "./History.css";
import Plx from "react-plx";
import AnchorLink from "react-anchor-link-smooth-scroll";
import AchievementsBgSlider from "../achievementsBgSlider/AchievementsBgSlider";
import A1 from "../img/achievements1.jpg";
import A2 from "../img/achievements2.jpg";
import A3 from "../img/achievements3.jpg";
import A4 from "../img/achievements4.jpg";
import A5 from "../img/achievements5.jpg";
import A6 from "../img/achievements6.png";
import A7 from "../img/achievements7.jpg";

const History = () => {

    const historyTop = [
        {
            start: 600,
            end: 750,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const histories = [
        {
            start: 900,
            end: 1100,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
    ];

    const timeline = [
        {
            start: 900,
            end: 1100,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
    ];

    const point1 = [
        {
            start: 1600,
            end: 1700,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const hImg1 = [
        {
            start: 900,
            end: 1100,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 1650,
            end: 1700,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const point2 = [
        {
            start: 1700,
            end: 1750,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 2250,
            end: 2300,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const hImg2 = [
        {
            start: 1700,
            end: 1750,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 2250,
            end: 2300,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const point3 = [
        {
            start: 2300,
            end: 2350,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 2850,
            end: 2900,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const hImg3 = [
        {
            start: 2300,
            end: 2350,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 2850,
            end: 2900,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const point4 = [
        {
            start: 2900,
            end: 2950,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 3450,
            end: 3500,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const hImg4 = [
        {
            start: 2900,
            end: 2950,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 3450,
            end: 3500,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const point5 = [
        {
            start: 3500,
            end: 3550,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 4150,
            end: 4200,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const hImg5 = [
        {
            start: 3500,
            end: 3550,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 4150,
            end: 4200,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const point6 = [
        {
            start: 4200,
            end: 4250,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 4750,
            end: 4800,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const hImg6 = [
        {
            start: 4200,
            end: 4250,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 4750,
            end: 4800,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const point7 = [
        {
            start: 4800,
            end: 4850,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
    ];

    const hImg7 = [
        {
            start: 4800,
            end: 4850,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
    ];

    return(
        <div className="history">
            <AchievementsBgSlider />
            <Plx className="history-top" parallaxData={ historyTop }>
                <h2 className="history-title">
                    これまでの施工実績
                </h2>
                <p className="history-message">
                    大分県内をはじめ、九州の多くのエリアにて、私たちの技術が活かされました。<br/>
                    代表的な施工例を以下でご覧いただけます。
                </p>
            </Plx>
            <Plx className="histories" parallaxData={ histories }>
                <Plx className="history-block" parallaxData={ hImg1 }>
                    <div className="history-img">
                        <img src={A1} alt="中村設備工業" />
                    </div>
                    <div className="history-text">
                        <p className="h-text1">
                            平成3年 ハーモニーランド様 <br/>
                            「ハーモニートレイン」レール
                        </p>
                    </div>
                </Plx>
                <Plx className="history-block" parallaxData={ hImg2 }>
                    <div className="history-img">
                        <img src={A2} alt="中村設備工業" />
                    </div>
                    <div className="history-text">
                        <p className="h-text1">
                            平成４年 城島高原パーク 様<br/>
                            「ジュピター」レール
                        </p>
                    </div>
                </Plx>
                <Plx className="history-block" parallaxData={ hImg3 }>
                    <div className="history-img">
                        <img src={A3} alt="中村設備工業" />
                    </div>
                    <div className="history-text">
                        <p className="h-text1">
                            平成６年 ガレリア竹町のアーケード
                        </p>
                    </div>
                </Plx>
                <Plx className="history-block" parallaxData={ hImg4 }>
                    <div className="history-img">
                        <img src={A4} alt="中村設備工業" />
                    </div>
                    <div className="history-text">
                        <p className="h-text1">
                            平成９年 東九州自動車道 防球ネット
                        </p>
                    </div>
                </Plx>
                <Plx className="history-block" parallaxData={ hImg5 }>
                    <div className="history-img">
                        <img src={A5} alt="中村設備工業" />
                    </div>
                    <div className="history-text">
                        <p className="h-text1">
                            平成１3年 昭和電工ドーム大分
                        </p>
                    </div>
                </Plx>
                <Plx className="history-block" parallaxData={ hImg6 }>
                    <div className="history-img">
                        <img src={A6} alt="中村設備工業" />
                    </div>
                    <div className="history-text">
                        <p className="h-text1">
                            平成１６年 大分マリンパレス水族館うみたまご 様
                        </p>
                    </div>
                </Plx>
                <Plx className="history-block" parallaxData={ hImg7 }>
                    <div className="history-img">
                        <img src={A7} alt="中村設備工業" />
                    </div>
                    <div className="history-text">
                        <p className="h-text1">
                            令和２年 線路敷ボードウォーク広場
                        </p>
                    </div>
                </Plx>
            </Plx>
            <Plx className="timeline" parallaxData={ timeline }>
                <AnchorLink href="/#h1" className="point point1">
                    <Plx className="point-active" parallaxData={ point1 } />
                </AnchorLink>
                <AnchorLink href="/#h2" className="point point2">
                    <Plx className="point-active" parallaxData={ point2 } />
                </AnchorLink>
                <AnchorLink href="/#h3" className="point point3">
                    <Plx className="point-active" parallaxData={ point3 } />
                </AnchorLink>
                <AnchorLink href="/#h4" className="point point4">
                    <Plx className="point-active" parallaxData={ point4 } />
                </AnchorLink>
                <AnchorLink href="/#h5" className="point point5">
                    <Plx className="point-active" parallaxData={ point5 } />
                </AnchorLink>
                <AnchorLink href="/#h6" className="point point6">
                    <Plx className="point-active" parallaxData={ point6 } />
                </AnchorLink>
                <AnchorLink href="/#h7" className="point point7">
                    <Plx className="point-active" parallaxData={ point7 } />
                </AnchorLink>
            </Plx>
            <div className="h1" id="#h1" />
            <div className="h2" id="#h2" />
            <div className="h3" id="#h3" />
            <div className="h4" id="#h4" />
            <div className="h5" id="#h5" />
            <div className="h6" id="#h6" />
            <div className="h7" id="#h7" />
        </div> 
    )
}

export default History;