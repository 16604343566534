import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Top, Achievements, Specs, Machines, Products, Interiors } from "./templates";

const Router = () => {
    return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/achievements" element={<Achievements />} />
            <Route path="/specs" element={<Specs />} />
            <Route path="/machines" element={<Machines />} />
            <Route path="/products" element={<Products />} />
            <Route path="/interiors" element={<Interiors />} />
        </Routes>
      </BrowserRouter>
    )
  };
  
  export default Router;