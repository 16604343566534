import React from "react";
import "./TopBgSlider.css";
import Plx from "react-plx/lib/Plx";
import Bg1 from "../img/bg1.jpg";
import Bg2 from "../img/bg2.jpg";
import Bg3 from "../img/bg3.jpg";
import Bg4 from "../img/bg4.jpg";
import Bg5 from "../img/bg5.jpg";

const TopBgSlider = () => {
    const slider = [
        {
            start: 19700,
            end: 20000,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];
    return(
        <Plx className="top-bg-slider" parallaxData={ slider }>
            <div className="bg-slider">
                <div className="bg1">
                    <img src={ Bg1 } alt="中村設備工業" />
                </div>
                <div className="bg2">
                    <img src={ Bg2 } alt="中村設備工業" />
                </div>
                <div className="bg3">
                    <img src={ Bg3 } alt="中村設備工業" />
                </div>
                <div className="bg4">
                    <img src={ Bg4 } alt="中村設備工業" />
                </div>
                <div className="bg5">
                    <img src={ Bg5 } alt="中村設備工業" />
                </div>
            </div>
            <div className="bg-slider">
                <div className="bg1">
                    <img src={ Bg1 } alt="中村設備工業" />
                </div>
                <div className="bg2">
                    <img src={ Bg2 } alt="中村設備工業" />
                </div>
                <div className="bg3">
                    <img src={ Bg3 } alt="中村設備工業" />
                </div>
                <div className="bg4">
                    <img src={ Bg4 } alt="中村設備工業" />
                </div>
                <div className="bg5">
                    <img src={ Bg5 } alt="中村設備工業" />
                </div>
            </div>
        </Plx>     
    )
}
export default TopBgSlider;