import React from "react";
import { Header, Footer, ContactPopup } from "./index";
import MachineBlocks from "./machines/machineBlocks/MachineBlocks";

const Machines = () => {

    return(
        <div className="machines" id="page">
            <Header />
            <MachineBlocks />
            <Footer />
            <ContactPopup />
        </div>
    )
}
export default Machines;