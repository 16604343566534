import React from "react";
import "./Navigation.css";
import Facebook from "../../icons/Facebook";
import AnchorLink from "react-anchor-link-smooth-scroll";
import sdgsIcon from "../../../img/sdgs/sdgs-icon.png";


const NavLinks = () => {
    return (
        <ul>
            <div className="nav-items">
                <li>
                    <a href="/achievements" className="nav-link">
                        主な施工実績
                    </a>
                </li>
                <li>
                    <a href="/products" className="nav-link">
                        商品紹介
                    </a>
                </li>
                <li>
                    <a href="/specs" className="nav-link">
                        加工仕様 / 最小曲半径表
                    </a>
                </li>
                <li>
                    <a href="/machines" className="nav-link">
                        設備紹介
                    </a>
                </li>
                <li>
                    <a href="/interiors" className="nav-link">
                        曲げインテリア
                    </a>
                </li>
                <li>
                    <a href="https://www.oitabank.co.jp/business/support/sdgs/pdf/sdgs_319.pdf" target="_blank" rel="noreferrer"><div className="nav-sdgs-icon"><img src={ sdgsIcon } /></div><span className="nav-text">SDGsへの貢献</span></a>
                </li>
                <li>
                    <a href="https://www.facebook.com/1297137947061963/" target="_blank" rel="noreferrer" className="facebook"><Facebook className={"facebook-icon"} width={17} height={17} fill={"#fff"} /><span className="nav-text">最新情報はこちら</span></a>
                </li>
            </div>
        </ul>
    );
}

export default NavLinks;
