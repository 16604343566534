import React from "react";
import Hero from "./top/hero/Hero";
import Details from "./top/details/Details";
import { Header, Footer, ContactPopup } from "./index";

const Top = () => {
    return(
        <div className="wrapper" id="page">
            <Header />
            <div className="bg-logo" />
            <Hero />
            <div className="clear" />
            <Details />
            <Footer />
            <ContactPopup />
        </div>     
    )
}
export default Top;