import React from "react";
import "./Radius.css";
import R1 from "../img/radius-table01.jpg";
import R2 from "../img/radius-table02.jpg";
import R3 from "../img/radius-table03.jpg";
import R4 from "../img/radius-table04.jpg";
import R5 from "../img/radius-table05.jpg";
import R6 from "../img/radius-table06.jpg";
import R7 from "../img/radius-table07.jpg";
import R8 from "../img/radius-table08.gif";
import R9 from "../img/radius-table09.gif";

const Radius = () => {
    
    return(
        <div className="radius">
            <p className="radius-text">
                形鋼ごとに曲げ最小半径を表示しています。<br/>
                お急ぎの方、またはご不明点がございましたら、お気軽に問い合わせください。
            </p>
            <div className="divider" />
            <div className="radius-block">

                <div className="radius-inner col-12">
                <h4>H形鋼 (アーチ型)</h4>
                </div>

                <div className="radius-inner col-6">
                <table className="radius-2">
                    <thead>
                        <tr>
                            <th scope="cols">細巾鋼</th>
                            <th scope="cols" className="radius-img"><img src={ R1 } /></th>
                        </tr>
                    </thead>
                        <tbody>
                        <tr>
                            <td>100×50×5/7</td>
                            <td>800</td>
                        </tr>
                        <tr>
                            <td>125×60×6/8</td>
                            <td>1200</td>
                        </tr>
                        <tr>
                            <td>150×75×5/7</td>
                            <td>1800</td>
                        </tr>
                        <tr>
                            <td>175×90×5/8</td>
                            <td>3500</td>
                        </tr>
                        <tr>
                            <td>200×100×5.5/8</td>
                            <td>4000</td>
                        </tr>
                    <tr>
                            <td>250×125×6/9</td>
                            <td>7000</td>
                        </tr>
                    <tr>
                            <td>300×150×6.5/9</td>
                            <td>20000</td>
                        </tr>
                    </tbody>
                </table>
                </div>

            {/*-- End H形鋼 --*/}

                <div className="radius-inner col-6">
                <table className="radius-2">
                    <thead>
                        <tr>
                            <th scope="cols">支保鋼</th>
                            <th scope="cols" className="radius-img"><img src={ R2 } /></th>
                        </tr>
                    </thead>
                        <tbody>
                        <tr>
                            <td>100×100×6/8</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>125×125×6.5/9</td>
                            <td>1500</td>
                        </tr>
                        <tr>
                            <td>150×150×7/10</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>175×175×7.5/11</td>
                            <td>5000</td>
                        </tr>
                        <tr>
                            <td>200×200×8/12</td>
                            <td>7000</td>
                        </tr>
                    </tbody>
                </table>
                </div>

            </div>{/*-- End 支保鋼 --*/}

            <div className="radius-block">

                <div className="radius-inner col-6">
                <h4>I形鋼・H形鋼 (ホイスト曲)</h4>
                <table className="radius-2">
                    <thead>
                        <tr>
                            <th scope="cols">I形鋼<br/>
                        H形鋼</th>
                            <th scope="cols">3-イ<br/>
                        5-イ</th>
                        </tr>
                    </thead>
                        <tbody>
                        <tr>
                            <td>100×75×5</td>
                            <td>450</td>
                        </tr>
                        <tr>
                            <td>100×100×6</td>
                            <td>600</td>
                        </tr>
                        <tr>
                            <td>125×75×5.5</td>
                            <td>500</td>
                        </tr>
                        <tr>
                            <td>150×75×5.5</td>
                            <td>500</td>
                        </tr>
                        <tr>
                            <td>150×125×8.5</td>
                            <td>1000</td>
                        </tr>
                    <tr>
                            <td>200×100×7</td>
                            <td>750</td>
                        </tr>
                    <tr>
                            <td>250×125×7.5</td>
                            <td>1000</td>
                        </tr>
                    <tr>
                            <td>300×150×8</td>
                            <td>1500</td>
                        </tr>
                    <tr>
                            <td>350×150×9</td>
                            <td>2000</td>
                        </tr>
                    <tr>
                            <td>400×150×10</td>
                            <td>4000</td>
                        </tr>
                    </tbody>
                </table>

                </div>{/*-- End I形鋼・H形鋼 --*/}

                <div className="radius-inner col-6">
                <h4>溝形鋼</h4>
                <table className="radius-2">
                    <thead>
                        <tr>
                            <th scope="cols">溝形鋼</th>
                            <th scope="cols">6-イ</th>
                        <th scope="cols">6-ロ</th>
                        </tr>
                    </thead>
                        <tbody>
                        <tr>
                            <td>75×40×5</td>
                            <td>300</td>
                        <td>400</td>
                        </tr>
                        <tr>
                            <td>100×50×5</td>
                            <td>400</td>
                        <td>500</td>
                        </tr>
                        <tr>
                            <td>125×65×6</td>
                            <td>500</td>
                        <td>600</td>
                        </tr>
                        <tr>
                            <td>150×75×6.5</td>
                            <td>550</td>
                        <td>650</td>
                        </tr>
                        <tr>
                            <td>200×80×7.5</td>
                            <td>700</td>
                        <td>800</td>
                        </tr>
                    <tr>
                            <td>200×90×8</td>
                            <td>800</td>
                        <td>900</td>
                        </tr>
                    <tr>
                            <td>250×90×9</td>
                            <td>900</td>
                        <td>1000</td>
                        </tr>
                    <tr>
                            <td>300×90×9</td>
                            <td>900</td>
                        <td>1000</td>
                        </tr>
                    <tr>
                            <td>380×100×10.5</td>
                            <td>1400</td>
                        <td>2000</td>
                        </tr>
                    </tbody>
                </table>

                </div>{/*-- End 溝形鋼 --*/}

            </div>

            <div className="radius-block">

                <div className="radius-inner col-6">
                <h4>アングル（大型）</h4>
                <table className="radius-2">
                    <thead>
                    <tr>
                        <th scope="cols" className="material">素材</th>
                        <th scope="cols">1-イ<br/>
                        2-イ<br/>
                        2-ロ</th>
                        <th scope="cols">1-ロ<br/>
                        2-ロ<br/>
                        2-ニ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>6×75×75</td>
                        <td>500</td>
                        <td>650</td>
                    </tr>
                    <tr>
                        <td>10×90×90</td>
                        <td>650</td>
                        <td>800</td>
                    </tr>
                    <tr>
                        <td>10×100×100</td>
                        <td>700</td>
                        <td>900</td>
                    </tr>
                    <tr>
                        <td>12×130×130</td>
                        <td>900</td>
                        <td>1200</td>
                    </tr>
                    <tr>
                        <td>15×150×150</td>
                        <td>1200</td>
                        <td>1700</td>
                    </tr>
                    <tr>
                        <td>20×200×200</td>
                        <td>2800</td>
                        <td>5000</td>
                    </tr>
                    </tbody>
                </table>

                </div>{/*-- End アングル (大型) --*/}

                <div className="radius-inner right col-6">
                <h4>アングル（小型）</h4>
                <table className="radius-2">
                    <thead>
                    <tr>
                        <th scope="cols" className="material">素材</th>
                        <th scope="cols">1-イ<br/>
                        2-イ<br/>
                        2-ハ</th>
                        <th scope="cols">1-ロ<br/>
                        2-ロ<br/>
                        2-ニ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>3×25×25</td>
                        <td>250R</td>
                        <td>500R</td>
                    </tr>
                    <tr>
                        <td>3×30×30</td>
                        <td>250</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>3×40×40</td>
                        <td>250</td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <td>6×50×50</td>
                        <td>300</td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <td>6×65×65</td>
                        <td>400</td>
                        <td>600</td>
                    </tr>
                    </tbody>
                </table>

                </div>{/*-- End アングル (小型) --*/}

            </div>

            <div className="radius-block col-6">

                <div className="radius-inner col-12">
                    <table className="radius-1">
                        <tr>
                            <th scope="row">レール</th>
                            <td className="radius-img"><img src={ R5 } /></td>
                        <td className="radius-img"><img src={ R6 } /></td>
                        <td className="radius-img"><img src={ R7 } /></td>
                        </tr>
                        <tr>
                            <th scope="row">15</th>
                            <td>800</td>
                        <td>700</td>
                        <td>700</td>
                        </tr>
                    <tr>
                            <th scope="row">22</th>
                            <td>800</td>
                        <td>800</td>
                        <td>800</td>
                        </tr>
                    <tr>
                            <th scope="row">30</th>
                            <td>800</td>
                        <td>1000</td>
                        <td>1000</td>
                        </tr>
                    <tr>
                            <th scope="row">37</th>
                            <td>1200</td>
                        <td>1500</td>
                        <td>1500</td>
                        </tr>
                    <tr>
                            <th scope="row">50</th>
                            <td>2000</td>
                        <td>2500</td>
                        <td>2500</td>
                        </tr>
                    <tr>
                            <th scope="row">60</th>
                            <td>3000</td>
                        <td>4000</td>
                        <td>4000</td>
                        </tr>
                    </table>

                </div>

            </div>{/*-- End レール --*/}

            <div className="radius-block">

                <div className="radius-inner col-6">
                <h4>平鋼（SS基準）</h4>
                <table className="radius-2">
                    <thead>
                    <tr>
                        <th scope="cols">平鋼</th>
                        <th scope="cols" className="radius-img"><img src={ R8 } /></th>
                        <th scope="cols" className="radius-img"><img src={ R9 } /></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>3×32</td>
                        <td>250</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>4.5×44</td>
                        <td>300</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>6×50</td>
                        <td>350</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>6×65</td>
                        <td>400</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>9×75</td>
                        <td>400</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>9×90</td>
                        <td>500</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>12×100</td>
                        <td>600</td>
                        <td>250</td>
                    </tr><tr>
                        <td>16×100</td>
                        <td>600</td>
                        <td>300</td>
                    </tr><tr>
                        <td>19×125</td>
                        <td>700</td>
                        <td>400</td>
                    </tr><tr>
                        <td>25×150</td>
                        <td>800</td>
                        <td>400</td>
                    </tr><tr>
                        <td>32×150</td>
                        <td>1800</td>
                        <td>500</td>
                    </tr><tr>
                        <td>36×200</td>
                        <td>2500</td>
                        <td>500</td>
                    </tr>
                    </tbody>
                </table>

                </div>{/*-- End 平鋼 --*/}

                <div className="radius-inner right col-6">
                <h4>パイプ</h4>
                <table className="radius-2">
                    <tbody>
                    <tr>
                        <th scope="cols" colspan="3">パイプ</th>
                        <th scope="cols" rowspan="2">12-イ</th>
                    </tr>
                            <tr>
                                <td>A</td>
                                <td>B</td>
                                <td>外径</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>1/2</td>
                                <td>21.7</td>
                                <td>200R</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>3/4</td>
                                <td>27.2</td>
                                <td>250</td>
                            </tr>
                            <tr>
                                <td>25</td>
                                <td>1</td>
                                <td>34.0</td>
                                <td>250</td>
                            </tr>
                            <tr>
                                <td>32</td>
                                <td>1 1/4</td>
                                <td>42.7</td>
                                <td>250</td>
                            </tr>
                            <tr>
                                <td>40</td>
                                <td>1 1/2</td>
                                <td>48.6</td>
                                <td>280</td>
                            </tr>
                            <tr>
                                <td>50</td>
                                <td>2</td>
                                <td>60.5</td>
                                <td>300</td>
                            </tr>
                            <tr>
                                <td>65</td>
                                <td>2 1/2</td>
                                <td>76.3</td>
                                <td>350</td>
                            </tr>
                            <tr>
                                <td>80</td>
                                <td>3</td>
                                <td>86.1</td>
                                <td>500</td>
                            </tr>
                            <tr>
                                <td>100</td>
                                <td>4</td>
                                <td>114.3</td>
                                <td>500</td>
                            </tr>
                            <tr>
                                <td>125</td>
                                <td>5</td>
                                <td>139.8</td>
                                <td>750</td>
                            </tr>
                            <tr>
                                <td>150</td>
                                <td>6</td>
                                <td>165.2</td>
                                <td>1000</td>
                            </tr>
                            <tr>
                                <td>175</td>
                                <td>7</td>
                                <td>18.7</td>
                                <td>1500</td>
                            </tr>
                            <tr>
                                <td>200</td>
                                <td>8</td>
                                <td>216.3</td>
                                <td>1500</td>
                            </tr>
                            <tr>
                                <td>250</td>
                                <td>10</td>
                                <td>267.4</td>
                                <td>2500</td>
                            </tr>
                    </tbody>
                </table>

                </div>{/*-- End パイプ --*/}

            </div>

        </div> 
    )
}

export default Radius;