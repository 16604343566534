import React from "react";
import "./Details.css";
import AOS from "aos";
import "aos/dist/aos.css";
import D1 from "../img/details1.jpg";
import D2 from "../img/details2.jpg";
import D3 from "../img/details3.jpg";
import D4 from "../img/details4.jpg";
import D5 from "../img/details5.jpg";

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

const Details = () => {
    React.useEffect(() => { 
        AOS.init({
            duration: 2000,
        });
    },[]);

    return(
        <div className="details" id="details">
            <div className="details-item">
                <div className="details-block left img">
                    <img className="details-img" data-aos="fade-up" src={D1} />
                </div>
                <div className="details-block right">
                    <h3 className="details-title">
                        曲げの中村
                    </h3>
                    <img className="details-img-resp" src={D1} />
                    <p className="details-description">
                        若さと活気あふれる職場だからこそできるスピーディーでパワフルな仕事。<br/><br/>
                        曲げ加工という専門技術を確実に次世代へと伝えつつ、数々の実績を重ね、皆さまの信頼にお応えできる製品製造の努力を日々続けてまいりました。<br/><br/>
                        私たちの曲げ技術は日常の様々な建築物の中で生かされています。
                    </p>
                    <div className="details-links">
                        <a href="/achievements" className="details-link-btn">
                            主な施工実績
                        </a>
                    </div>
                </div>
            </div>

            <div className="details-item">
                <div className="details-block left">
                    <h3 className="details-title">
                        サービス
                    </h3>
                    <img className="details-img-resp" src={D2} />
                    <p className="details-description">
                        豊富な経験に裏付けされた高い曲げ技術により、正確無比な高品質商品の提供を目指しております。<br/><br/>
                        製品情報、対応可能加工仕様、曲げ半径は以下よりご確認ください。<br/><br/>
                        また、製品詳細や不明点などのお問い合わせは随時受け付けております。
                    </p>
                    <div className="details-links">
                        <a href="/products" className="details-link-btn">
                            製品紹介
                        </a>
                        <a href="/specs" className="details-link-btn">
                            加工仕様 / 最小曲半径表
                        </a>
                    </div>
                </div>
                <div className="details-block right img">
                    <img className="details-img" data-aos="fade-up" src={D2} />
                </div>
            </div>
            
            <div className="details-item">
                <div className="details-block left img">
                    <img className="details-img" data-aos="fade-up" src={D3} />
                </div>
                <div className="details-block right">
                    <h3 className="details-title">
                        充実の設備環境
                    </h3>
                    <img className="details-img-resp" src={D3} />
                    <p className="details-description">
                        技術力はもちろん、充実した設備により的確にお客様のニーズにお応えいたします。<br/><br/>
                        新しい設備も導入し、さらに幅広く、スピーディーな対応を実現しています。
                    </p>
                    <div className="details-links">
                        <a href="/machines" className="details-link-btn">
                            設備紹介
                        </a>
                    </div>
                </div>
            </div>

            <div className="details-item">
                <div className="details-block left">
                    <h3 className="details-title">
                        曲げインテリア
                    </h3>
                    <img className="details-img-resp" src={D4} />
                    <p className="details-description">
                        より曲げを身近に感じていただくことができる工芸品、アート作品も製作しております。<br/><br/>
                        お家のインテリアの一部として、大事な人へのギフトとして、美しい曲げの魅力をぜひ感じてください。
                    </p>
                    <div className="details-links">
                        <a href="/interiors" className="details-link-btn">
                            インテリア
                        </a>
                    </div>
                </div>
                <div className="details-block right img">
                    <img className="details-img" data-aos="fade-up" src={D4} />
                </div>
            </div>

            <div className="details-item">
                <div className="details-block left img">
                    <img className="details-img" data-aos="fade-up" src={D5} />
                </div>
                <div className="details-block right">
                    <h3 className="details-title details-contact-title">
                        「曲げ」が必要ですか？
                    </h3>
                    <img className="details-img-resp" src={D5} />
                    <p className="details-description">
                        個人様・企業様問わず、幅広いご用件に対応できる準備ができております。<br/><br/>
                        ご不明点がある場合でも、お気軽にご相談ください。一人一人に丁寧に対応いたします。<br/><br/>
                        ご相談・お見積もりはメール、電話及びFAXにて受け付けております。
                    </p>
                    <div className="details-links">
                        <a href="mailto:info@nakamurasetubi.co.jp" className="details-link-btn">
                            メールでお問い合わせ
                        </a>
                        <div className="details-number">
                            電話：<a href="tel:0975513218">097-551-3218</a>
                        </div>
                        <div className="details-number">
                            FAX：097-551-3269
                        </div>
                        <div className="details-address">
                            〒870-0138<br/>大分県大分市原川1丁目1-16
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    )
}
export default Details;