import React, { useEffect, useState } from "react";
import "./Hero.css";
import Plx from "react-plx";
import TopBgSlider from "../topBgSlider/TopBgSlider";
import { Link as Scroll } from "react-scroll";
import { DownArrow } from "../../index";
import topVideo from "../img/top-video.mp4";

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

const Hero = () => {

    const [isScrolled, setIsScrolled] = useState(false)

    const Scrolled = () => {
        window.scrollY > 500
            ? setIsScrolled(true)
            : setIsScrolled(false)
    }

    useEffect(() => {
    window.addEventListener('scroll', Scrolled)
    return () => window.removeEventListener('scroll', Scrolled)
    }, [])

    const hero = [
        {
            start: 20200,
            end: 20800,
            properties: [
            {
                startValue: vw,
                endValue: 0,
                property: 'width',
            },
            ],
        },
    ];

    const heroBg = [
        {
            start: 0,
            end: 400,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const heroTitle = [
        {
            start: 600,
            end: 750,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const downArrow = [
        {
            start: 20200,
            end: 20500,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const section = [
        {
            start: 11700,
            end: 12200,
            properties: [
            {
                startValue: vh,
                endValue: 0,
                property: 'height',
            },
            ],
        },
        {
            start: 12200,
            end: 12700,
            properties: [
            {
                startValue: 0,
                endValue: vh,
                property: 'height',
            },
            ],
        },
    ];

    const sectionImg1 = [
        {
            start: 800,
            end: 1100,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'scale',
            },
            ],
            
        },
        {
            start: 6100,
            end: 6400,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const text1 = [
        {
            start: 800,
            end: 1100,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 1100,
            end: 6000,
            properties: [
                {
                    startValue: 0,
                    endValue: 10 / 100 * vh,
                    property: 'translateY',
                },
            ],
        },
        {
            start: 6000,
            end: 6100,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const sectionImg2 = [
        {
            start: 6300,
            end: 6600,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 11999,
            end: 12000,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const text2 = [
        {
            start: 6600,
            end: 6900,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 6900,
            end: 11900,
            properties: [
                {
                    startValue: 0,
                    endValue: 10 / 100 * vh,
                    property: 'translateY',
                },
            ],
        },
        {
            start: 11999,
            end: 12000,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const sectionImg3 = [
        {
            start: 12200,
            end: 12201,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 18099,
            end: 18100,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const text3 = [
        {
            start: 12700,
            end: 12800,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 12800,
            end: 17700,
            properties: [
                {
                    startValue: 0,
                    endValue: 10 / 100 * vh,
                    property: 'translateY',
                },
            ],
        },
        {
            start: 18099,
            end: 18100,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    const sectionImg3Overlay = [
        {
            start: 17400,
            end: 18100,
            properties: [
            {
                startValue: 0,
                endValue: vh,
                property: 'height',
            },
            ],
        },
    ];

    const heroMessage = [
        {
            start: 18500,
            end: 18800,
            properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
            ],
        },
        {
            start: 20200,
            end: 20500,
            properties: [
            {
                startValue: 1,
                endValue: 0,
                property: 'opacity',
            },
            ],
        },
    ];

    return(
        <>
        <Plx id="hero" className="hero" parallaxData={ hero }>
            <Plx className="hero-bg" parallaxData={ heroBg }>
                <div className="hero-bg-filter" />
            </Plx>
            <Plx className="hero-title-bg" parallaxData={ heroTitle } >
                <h1 className="hero-title">&#12850;中村設備工業</h1>
                <p className="award">第15回おおいたビジネス オブ ザ・イヤー<br/>(平成29年2月27日)</p>
                <p className="award">はばたく中小企業・小規模事業者300社 担い手確保部門<br/>(平成30年3月26日)</p>
            </Plx>
            <Plx className="down-arrow" parallaxData={ downArrow }>
                { isScrolled? 
                    <Scroll to="details" className="skip" duration={0}>
                        SKIP
                    </Scroll>:
                    <Scroll to="details" smooth={true} duration={25000}>
                        <DownArrow className={"down-icon"} width={70} height={70} fill={"#fff"} />
                    </Scroll>
                }
            </Plx>
            <Plx className="section" parallaxData={ section }>
                <Plx className="section-img1" parallaxData={ sectionImg1 }>
                    <video className='top-video' autoPlay loop muted>
                        <source src={topVideo} type='video/mp4'/>
                    </video>
                    <div className="section-overlay" />
                </Plx>
                <Plx className="section-img2" parallaxData={ sectionImg2 }>
                    <TopBgSlider />
                    <div className="section-overlay" />
                </Plx>
                <Plx className="section-img3" parallaxData={ sectionImg3 }>
                    <div className="section-img3-left" />
                    <div className="section-img3-right" />
                    <div className="section-overlay" />
                    <Plx className="section-text3" parallaxData={ text3 }>
                        <h2 className="section-title">
                            人と街をつなぐ<br/>
                            曲げのスペシャリスト集団
                        </h2>
                    </Plx>
                    <Plx className="section-img3-overlay" parallaxData={ sectionImg3Overlay } />
                </Plx>
                <Plx className="hero-message-outer" parallaxData={ heroMessage }>
                    <h2 className="hero-message">
                        曲げは中村へ
                    </h2>
                </Plx>
            </Plx>{/* end section */}
            <Plx className="section-text1" parallaxData={ text1 }>
                <h2 className="section-title">
                    世界に誇る<br/>
                    洗練された技術と実績
                </h2>
            </Plx>
            <Plx className="section-text2" parallaxData={ text2 }>
                <h2 className="section-title">
                    ニーズに応える<br/>
                    充実の設備環境
                </h2>
            </Plx>
        </Plx>  
        {/* hero end */}

        <div className="hero-resp">
            <div className="hero-bg">
                <div className="hero-bg-filter" />
            </div>
            <div className="hero-title-bg">
                <h1 className="hero-title">&#12850;中村設備工業</h1>
                <p className="award">第15回おおいたビジネス オブ ザ・イヤー<br/>(平成29年2月27日)</p>
                <p className="award">はばたく中小企業・小規模事業者300社 担い手確保部門<br/>(平成30年3月26日)</p>
            </div>
        </div>
        </>   
    )
}
export default Hero;